<template>
  <div>
    <dropbox clientId="7tz4eaobdlgu15s" />
  </div>
</template>
<script>
import dropbox from "../dropbox.vue";
export default {
  components: { dropbox },
};
</script>
